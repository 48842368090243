<template>
  <div>
    <Navbar mode="solid" class="fixed w-full top-0" />
    <main class="page-main">
      <div class="wrapper">
        <h1>Refund Policy</h1>
        <span class="updated-at">11. Aug 2021</span>
        <h2>Foreword</h2>
        <p>
          This Policy governs Travel Afrique's Refund Policy for Travelers
          ("Refund Policy"). The Refund Policy applies in addition to Travel
          Afrique's <a href="/terms">Terms and Conditions</a> ("Terms and
          Conditions"). The Refund Policy applies to travelers ("Travelers",
          "Traveler") who book and pay for travel services from Service
          Providers (e.g. travel agencies, hotels, etc.) through Travel
          Afrique's Platform and experience a Travel Problem (as defined below).
        </p>
        <p>
          The Traveler's rights under this Refund Policy take precedence over
          the Service Provider's cancellation policy set forth in the Service
          Provider's offers. By using the Travel Afrique Platform as a Traveler
          or a Service Provider, you are indicating that you have read and that
          you understand and agree to be bound by this Refund Policy.
        </p>
        <h2>1. Travel Problem</h2>
        <p>
          A "Travel Problem" occurs when the Service Provider (i) cancels a
          booking before the scheduled start date or shortly after the start
          date of the booked service.
        </p>
        <p>
          If only particular parts of the trip are canceled, provided that the
          trip does not endanger the health or life of the traveler due to
          unforeseeable circumstances mentioned above, the Service Provider is
          obliged to offer an equivalent substitute Service at no extra cost. If
          this is not possible, the amount paid for the partial Service shall be
          refunded to the traveler, however, the remaining part of the booked
          service or services will not be cancelled.
        </p>
        <h2>2. The Refund Policy</h2>
        <h3>2.1. Cancellation by Service Providers</h3>
        <p>
          Service Providers may cancel a Service at any time prior to departure
          if it is not possible for them to offer the booked service due to
          terrorism, natural disasters, pandemic measures, political instability
          or other unforeseen circumstances.
        </p>
        <p>
          If you are a Traveler and suffer a Travel Problem prior to the
          delivery of your booked Service, we agree, at our discretion, to
          either (i) refund the full amount paid by you through the Travel
          Afrique Platform or (ii) make appropriate efforts to help you find and
          book another Service that is comparable or better in terms of quality
          to the previous one in your original booking at no additional costs.
          If no equivalent Service can be provided on the specified original
          date, the Traveler may transfer all amounts paid to another date or
          request a full refund.
        </p>
        <p>
          In general, Travel Afrique will determine whether a problem reported
          by a Traveler qualifies as a Travel Problem, whether to refund a
          Traveler or rebook a Trip who has a Travel Problem, and whether an
          alternative offer is comparable or better.
        </p>
        <p>
          Travel Afrique is not liable for any incidental expenses a Traveler
          incurs as a result of any cancellation of a booking, including but not
          limited to visas, vaccinations, travel insurance deductibles or
          non-refundable flights.
        </p>
        <h3>2.2. Cancellation by Traveler</h3>
        <p>
          If you cancel some or all parts of your booking, cancellation fees
          will apply. Cancellation will not be effective until we receive
          written confirmation of cancellation. An email message is already
          sufficient. Each trip has its own cancellation policy, which is
          individually defined by each Service Provider. The cancellation policy
          and possible refunds are displayed on each offer, which the Traveler
          can access on his Travel Afrique Account. In his or her Travel Afrique
          Account the Traveler has an overview of the amount he or she can be
          refunded up to how many days before the delivery of the booked
          Service.
        </p>
        <h3>2.2 Use of users content</h3>
        <p>
          In order to promote the Travel Afrique platform and increase the
          exposure of offers and Services to potential Customers, offers and
          services and content may be displayed on other websites, in
          applications, in emails, and in online and offline advertisements. To
          support Members who speak different languages, offers and other
          content may be translated in whole or in part into other languages.
          Travel Afrique cannot guarantee the accuracy or quality of such
          translations, and Members are responsible for checking and verifying
          the accuracy of such translations. Travel Afrique's platform may
          include translations created by Google Translate or other translation
          services.
        </p>
        <h2>3. Conditions for making a Claim</h2>
        <p>
          To submit a valid claim for a Travel Problem and receive the refund
          with respect to the booking, you must meet each of the following
          conditions:
        </p>
        <ul>
          <li>you are the Traveler that booked the Service or Services;</li>
          <li>you must report the Travel Problem to us in writing (email us at
            contact(at)travelafrique.com) within 24 hours of discovering the
            existence of the Travel Problem, and you must inform us about the
            circumstances of the Travel Problem;
          </li>
          <li>you must respond to all inquiries from us for additional information
            or assistance regarding the Travel Problem within the time period
            defined by us;
          </li>
          <li>you must not have caused the Travel Problem, either directly or
            indirectly (by your act, neglect, or willful misconduct);
          </li>
          <li>unless Travel Afrique otherwise determines that the travel problem
            cannot be resolved, you must use reasonable efforts to attempt to
            resolve the circumstances of the Travel Problem with the Service
            Provider.
          </li>
        </ul>
        <h2>
          4. Minimum Quality Standards, Service Provider Responsibilities and
          Refunds to Travelers
        </h2>
        <h3>4.1. Responsibilities of a Service Provider</h3>
        <p>
          If you are a Service Provider, you are responsible for ensuring that
          the services you provide on the Travel Afrique Platform are
          accessible, reasonably and accurately described and safe and do not
          present the Traveler with Travel Problems, as set forth in these
          Terms. During the period in which the Traveler is using the booked
          Service(s), the Service Provider should be available or provide a
          third party to attempt in good faith to resolve any travel problems or
          other issues that may arise.
        </p>
        <h3>4.2. Refunds to Travelers</h3>
        <p>
          If you are a Service Provider and (i) Travel Afrique determines that a
          Traveler has suffered a Travel Problem in connection with a Service
          provided by you and (ii) Travel Afrique either compensates that
          Traveler (up to the total cost) or offers the Traveler an alternative
          Service, you agree to reimburse Travel Afrique for the amount paid by
          Travel Afrique within 14 days of Travel Afrique's request.
        </p>
        <p>
          If the trip is transferred to another Service Provider, you also agree
          to reimburse Travel Afrique for reasonable additional costs incurred
          to reassign the Traveler.
        </p>
        <h2>
          4.3. Refund Policy in relation to the Cancellation Policy of the
          Service Provider
        </h2>
        <p>
          As a Service Provider, you understand that the Travelers' rights under
          this Refund Policy will replace any cancellation terms you may choose.
          If you dispute the Travel Problem, you may notify us in via
          contact(at)travelafrique.com or by phone and provide us with
          information and evidence disputing the claims regarding the Travel
          Problem. To dispute a Travel Problem, you must make fair and good
          faith efforts to resolve the Travel Problem with the Traveler, unless
          Travel Afrique advises you that the Travel Problem cannot be resolved
          or the Traveler has cancelled the Service.
        </p>
        <h2>5. General Provisions</h2>
        <h3>5.1 No Assignment/No Insurance.</h3>
        <p>
          This Refund Policy is not an offer of insurance, does not issue
          insurance or constitute a contract of insurance, is not in lieu of any
          insurance purchased or to be purchased by the Traveler, and the
          Traveler hereunder has not paid any premium for the Refund Policy.
          Benefits under this Refund Policy are not assignable or transferable
          by you.
        </p>
        <h3>5.2 Modification or Termination</h3>
        <p>
          Travel Afrique reserves the right to modify or discontinue this Refund
          Policy at any time at its own discretion. If Travel Afrique changes
          this Refund Policy, we will publish the changes on the Travel Afrique
          Platform or inform you of the change, and Travel Afrique will continue
          to process all claims for travel issues made prior to the effective
          date of the change in accordance with the Policy then in effect.
        </p>
        <h3>5.3 Entire Agreement</h3>
        <p>
          This Refund Policy represents the sole and entire agreement between
          Travel Afrique and you with respect to the Refund Policy and replaces
          any prior oral or written understandings or agreements between Travel
          Afrique and you with respect to the Refund Policy.
        </p>
        <h2>6. Contacting Travel Afrique</h2>
        <p>
          If you have any questions about the Refund Policy, please email us at
          contact(at)travelafrique.com
        </p>
      </div>
    </main>
    <Footer />
    <BottomBar class="block sm:hidden fixed bottom-0 w-full" />
  </div>
</template>
<script>
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import BottomBar from "@/components/layout/BottomBar.vue";
export default { name: "Refund", components: { Navbar, Footer, BottomBar } };
</script>
<style scoped>
.page-main {
  @apply w-full px-4 pt-36 pb-16;
}

.wrapper {
  @apply w-full max-w-screen-sm;
  @apply relative left-1/2 transform -translate-x-1/2;
}
ul {
  @apply text-black-base;
}

ul li {
  @apply list-item ml-8 list-disc whitespace-pre-wrap my-1;
}
h1,
h2,
h3 {
  @apply font-semibold text-black-base;
}

p {
  @apply text-black-base leading-normal;
  @apply mt-2 mb-4;
}

h1 {
  @apply text-3xl leading-tight;
}

h2 {
  @apply text-2xl leading-9;
  @apply mt-8 mb-2;
}

h3 {
  @apply mt-4 mb-2 text-lg;
}

.updated-at {
  @apply text-black-lighter text-sm;
}
</style>